import React from 'react';

import './Contact.scss'; // Assuming you might want specific styles for the Contact Page itself
import PricingLink from '../components/header/PricingLink';
import ContactForm from '../components/forms/ContactForm';
import Header from '../components/header/Header';

const Contact = () => {
  return (
    <div className="contact">
      <Header>
        <PricingLink />
      </Header>
      <div className='contact-holder'>
        <div>
          <h1>Get in touch</h1>
          <p>Please fill out the form to get in touch with us.</p>
        </div>
        
        <ContactForm />
      </div>
     
    </div>
  );
};

export default Contact;
