import React, { useEffect, useState } from "react";
import "./Experiences.scss";
import { useExperiences } from "../context/ExperiencesContext";
import { useAuth } from "../context/AuthContext";
import ExperiencesAPI from "../services/ExperiencesAPI";
import moment from "moment";
import { getItem, getThumbnail } from "../services/Content";
import Experience from "../components/dashboard/Experience";

const Experiences = () => {
  const { experiences, fetchData } = useExperiences();
  const { organizationId, userId } = useAuth();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  

  // Hardcoded experiences
  const experienceOptions = [
    {
      label: "CRISPR & Sickle Cell",
      systemId: "2985689a-bf21-4e46-8596-f2f59c2b953d",
      guideId: "6d7a18d4-0f86-4989-a0c8-73b1c1819693",
    },
    {
      label: "Trouble in Sunshine Creek",
      systemId: "ae082427-fe04-49b8-9767-f332025bc23d",
      guideId: "0639a1ba-9407-4645-a014-85cb3e24fa65",
    },
  ];

  const [newExperience, setNewExperience] = useState({
    name: `Class ${experiences && experiences.length + 1}, ${moment().format("MMM Do YYYY")}`,
    organizationId: organizationId || "",
    userId: userId || "",
    systemId: "",
    guideId: "",
  });


  useEffect(() => {
    console.log("Loaded Experiences:", experiences);
    setNewExperience({
      name: `Class ${experiences && experiences.length + 1}, ${moment().format(
        "MMM Do YYYY"
      )}`,
      organizationId: organizationId || "",
      userId: userId || "",
      systemId: "",
      guideId: "",
    });
  }, [experiences]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExperience({ ...newExperience, [name]: value });
  };

  // Handle experience selection from dropdown
  const handleExperienceSelect = (e) => {
    const selectedExperience = experienceOptions.find(
      (option) => option.label === e.target.value
    );
    if (selectedExperience) {
      setNewExperience({
        ...newExperience,
        systemId: selectedExperience.systemId,
        guideId: selectedExperience.guideId,
      });
    }
  };



  const handleCreateExperience = async () => {
    try {
      await ExperiencesAPI.post(
        "experiences",
        {
          ...newExperience,
          experienceId: crypto.randomUUID(),
        },
        {
          requiresAuth: true,
        }
      );
      fetchData();
      setIsDialogOpen(false);
      setNewExperience({
        name: "",
        organizationId: organizationId || "",
        userId: userId || "",
        systemId: "",
        guideId: "",
      });
    } catch (error) {
      console.error("Error creating experience:", error);
    }
  };





  return (
    <div className="dashboard experiences">
      <h2>Experiences</h2>
      {experiences?.map((experience) => 
        <Experience experience={experience} key={experience.experienceId} />
        
      )}

      {/* Button to open the dialog */}
      <button onClick={() => setIsDialogOpen(true)}>
        Launch New Experience
      </button>

      {/* Dialog for creating a new experience */}
      {isDialogOpen && (
        <div className="dialog">
          <div className="dialog-content">
            <h3>Launch New Experience</h3>

            {/* Dropdown to select experience type */}
            <select onChange={handleExperienceSelect} defaultValue="">
              <option value="" disabled>
                Select Content
              </option>
              {experienceOptions.map((option) => (
                <option key={option.label} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>

            {/* Experience Name Input */}
            <input
              type="text"
              name="name"
              placeholder="My new experience"
              value={newExperience.name}
              onChange={handleInputChange}
            />

            {/* Read-only fields for systemId and guideId */}
            {/* <input
              type="text"
              name="systemId"
              placeholder="System ID"
              value={newExperience.systemId}
              readOnly
            />
            <input
              type="text"
              name="guideId"
              placeholder="Guide ID"
              value={newExperience.guideId}
              readOnly
            /> */}

            {/* Buttons to create or cancel */}
            <button onClick={handleCreateExperience}>Create</button>
            <button onClick={() => setIsDialogOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Experiences;
