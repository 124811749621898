import React, { useState } from 'react';
import './ContactForm.scss';

const ContactForm = () => {
  const [status, setStatus] = useState('');

  const submitForm = (ev) => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  }

  return (
    <form
      onSubmit={submitForm}
      action="https://formspree.io/f/mrgnqryj" // Replace {yourFormId} with your Formspree form ID
      method="POST"
      className="contact-form"
    >
      <label htmlFor="name">Your name</label>
      <input type="name" name="name" id="name" required />

      <label htmlFor="email">Email</label>
      <input type="email" name="email" id="email" required />

      <label htmlFor="message">What do you want to discuss?</label>
      <textarea name="message" id="message" required></textarea>

      {status === "SUCCESS" ? <p>Thanks! Talk to you soon.</p> : <button>Submit</button>}
      {status === "ERROR" && <p>Ooops! There was an error. Please try again.</p>}
    </form>
  );
};

export default ContactForm;
