import React, { useState } from "react";
import axios from "axios";
import styles from "./SignUp.scss";
import Header from "../../components/header/Header";
import PricingLink from "../../components/header/PricingLink";
import ContactLink from "../../components/header/ContactLink";
import AccountsAPI from "../../services/AccountsAPI";

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    organization: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    const { firstName, lastName, email, organization } = formData;
    if (!firstName || !lastName || !email || !organization) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      // Clear previous errors
      setError("");

      // Send a POST request to your AccountsAPI
      await AccountsAPI.post("/sign-up", formData);

      // Show thank you message
      setIsSubmitted(true);
    } catch (err) {
      setError("Something went wrong. Please try again later.");
      console.error(err);
    }
  };

  return (
    <div className="signup">

      {isSubmitted ? (
        <div className="thankYouMessage">
          <h2>Thank You!</h2>
          <p>Please check your email to complete your registration.</p>
        </div>
      ) : (
        <form className="form" onSubmit={handleSubmit}>
          <div className="signUpMessage">
            <h2>Sign up to start your 7-day trial</h2>
            <p>No credit card required.</p>
          </div>

          {error && <p className="error">{error}</p>}

          <label>First Name *</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />

          <label>Middle Name</label>
          <input
            type="text"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
          />

          <label>Last Name *</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />

          <label>Email *</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label>Organization *</label>
          <input
            type="text"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            required
          />

          <button type="submit">Start free trial</button>
        </form>
      )}
    </div>
  );
};

export default SignUp;
