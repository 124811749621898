import React, { useState } from "react";
import "./SortToggleButton.scss";
import { useAnalytics } from "../context/AnalyticsContext";

const SortToggleButton = ({ attribute, sortFunction, activeSort }) => {
  // State to track sort order: true for ascending, false for descending
  const [ascending, setAscending] = useState(true);

  const handleClick = () => {
    const newAscending = !ascending;
    // Toggle the sort order and sort the sessions
    setAscending(newAscending);
    sortFunction(attribute, newAscending);
  };

  const isActive = activeSort && activeSort.attribute === attribute;

  return (
    <button
      className={`sort-toggle-button ${isActive ? "active" : ""}`}
      onClick={handleClick}
      aria-label={`Sort by ${attribute} in ${
        !ascending ? "descending" : "ascending"
      } order`}
    >
      {ascending ? "↓" : "↑"}
    </button>
  );
};

export default SortToggleButton;
