import { useState } from "react";
import "./SessionCodeInput.scss";

const SessionCodeInput = ({ }) => {
  const [code, setCode] = useState("");

  const handleInputChange = (event) => {
    setCode(event.target.value);
  }

  const goToSession = (event) => {
    event.preventDefault();
    window.location = `/sessions/${code}`;
  }

  return (
    <div className="form-holder">
      {/* Want to check out the dashboard? */}
      <form onSubmit={goToSession} className="code-form">
        <input
          type="text"
          className="input-field"
          placeholder="Enter session code"
          value={code}
          onChange={handleInputChange}
        />
        <button type="submit" className="submit-button">
          ➔
        </button>
      </form>
    </div>
  );
}

export default SessionCodeInput;