import { getItem } from "../../services/Content";
import "./Experience.scss";
import "./DashboardGlobal.scss"
import React, { useEffect, useState } from "react";

const Experience = ({ experience }) => {
  const [systemLabel, setSystemLabel] = useState("");


  const [copiedId, setCopiedId] = useState(null);


  const viewerUrl = `https://viewer.10k.science/experiences/${experience.experienceId}`;


  useEffect(() => {
    getItem("systems", experience.systemId, false).then((response) =>
      setSystemLabel(response.name)
    );
  }, []);

  const copyToClipboard = async (url, experienceId) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopiedId(experienceId);
      setTimeout(() => setCopiedId(null), 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  return (
    <div key={experience.experienceId} className="experience grid-row">
      <div className="grid-column medium left">{experience.name}</div>
      <div className="grid-column wide left">
        <h2>{systemLabel}</h2>
      </div>

      <div className="grid-column medium right">
        <button
          className="copy-button"
          onClick={() => copyToClipboard(viewerUrl, experience.experienceId)}
        >
          {copiedId === experience.experienceId ? "Copied!" : "Copy link"}
        </button>
      </div>
      <div className="grid-column medium right">
        <a
          href={viewerUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="view-link"
        >
          Join
        </a>
      </div>
    </div>
  );
};

export default Experience;
