import "./List.scss";

const List = ({children}) => {
  return(
    <div className="list"> 
      {children}
    </div>
  )
}

export default List;