import React, { useState, useEffect } from 'react';
import './TopBar.scss'; // Assuming you have a CSS file for styles

const TopBar = ({ children, dashboard }) => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setVisible(currentScrollY > 150); // Adjust the value as needed
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`top-bar ${visible ? 'visible' : 'hidden'} ${dashboard ? "dashboard" : ""}`}>
      {children}
    </div>
  );
};

export default TopBar;
