import './Header.scss';
import Logo  from '../../images/logo.png';

import { usePageMetadata } from '../../context/PageMetadataContext';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';




const Header = ({ children, dashboard }) => {
  const { title, showTitleInHeader } = usePageMetadata();

  const {organizationName} = useAuth();
 

  return (
    <div className="header">
      <div className="header-left">
 
        <Link to="/">
          <img src ={Logo} width="140px"/>
        </Link>
        
      </div>

      {showTitleInHeader && (
        <div className="header-center">
          <h1>{title}</h1>
        </div>
      )}
      <div className="header-right">
        {children}
        {dashboard && organizationName && <h2>{organizationName}</h2>}
      </div>
    </div>
  );
}

export default Header;


