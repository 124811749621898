import React from "react";
import './Pricing.scss';
import Header from "../components/header/Header";
import Section from "../components/Section";
import SessionCodeInput from "../components/header/SessionCodeInput";
import ContactLink from "../components/header/ContactLink";

const Pricing = ({}) => {
  


  return (
    <div className="pricing">
      <Header>
        <ContactLink />
        <SessionCodeInput />
      </Header>
      <div className="title">
        
        <h2>10k Science Pricing</h2>
      </div>

      <Section>
        <div className="price-block">
          <PricePanel
            title="Pilot"
            description="The 10k Science platform evaluation includes student access to the Viewer, tracking by teachers/administrators via the Portal, and two hours of training via Zoom or in-person within 50 miles of Oakland, CA."
            link="https://buy.stripe.com/4gw9D9002bDZ9y0000"
            linkText="Purchase"
            price="$900"
          />
          <PricePanel
            title="Professional development day"
            description="Dynamoid offers a 6-hour on-site professional development program completed with loaner headsets, teacher training, and up to three classes experiencing the 10k Science Viewer and Portal."
            link="https://buy.stripe.com/14k8z53cecI34dG5kl"
            linkText="Purchase"
            price="$2500"
          />
          <PricePanel
            title="Organizational licenses"
            description="Volume licensing for K-12 and higher ed institutions. Contact us for more information at info@dynamoid.com."
            link="/contact"
            linkText="Contact us"
          />
        </div>
      </Section>
    </div>
  );
}

export default Pricing;

const PricePanel = ({ title, price, description, link, linkText }) => {

  const goToLink = (link) => {
    window.location = link;
  };

  return(
    <div className="price-panel">
      <h3>{title}</h3>
      <h4>{price}</h4>
      <p>{description}</p>
      <button onClick={() => goToLink(link)}>
        {linkText}
      </button>
    </div>
  )
}
