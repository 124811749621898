import { useState } from "react";
import "./AnalyticsHeader.scss";

const AnalyticsHeader = ({ experience, isPolling, setIsPolling}) => {
  
  

  return (
    <div className="analytics-header">
      {/* <h2>Analytics</h2>
      <h4>{experience}</h4> */}

      <div className="toggle">
        <label className="switch">
          <input
            type="checkbox"
            checked={isPolling}
            onChange={() => setIsPolling && setIsPolling((prev) => !prev)}
          />
          <span className="slider round"></span>
        </label>
        <h4>Live</h4>
      </div>
    </div>
  );
}

export default AnalyticsHeader;