import Header from '../components/header/Header';
import Section from '../components/Section';
import './Confirmation.scss';


const Confirmation = ({}) => {
  return (
    <div className="confirmation">
      <Header />
      <Section>
        <h3>
          Thank you for your purchase!
          <p>
            Someone from Dynamoid will reach out to you shortly to set up a time
            to discuss logistics and onboarding.
          </p>
          <p>Feel free to reach out to us at any time at info@dynamoid.com.</p>
        </h3>
      </Section>
    </div>
  );
}

export default Confirmation;