import chroma from "chroma-js";

const scale = chroma
  .scale(["#FFCFCF", "#FFF0A2", "#BDFFC4"])
  .mode("lch")
  .domain([0, 90]);

const gradientColor = (percent) => scale(parseFloat(percent)).hex();

export default gradientColor;
