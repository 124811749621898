import React, { createContext, useContext, useState } from 'react';

const PageMetadataContext = createContext();
const usePageMetadata = () => useContext(PageMetadataContext);

const PageMetadataProvider = ({ children }) => {
  const [title, setTitle] = useState('');
  const [showTitleInHeader, setShowTitleInHeader] = useState(true); 


  return (
    <PageMetadataContext.Provider value={{ title, setTitle, showTitleInHeader, setShowTitleInHeader }}>
      {children}
    </PageMetadataContext.Provider>
  );
};

export { PageMetadataProvider, usePageMetadata };