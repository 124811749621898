import "./SessionListItem.scss";
import "./DashboardGlobal.scss"
import chroma from "chroma-js";
import { Link } from "react-router-dom";
import moment from "moment";
import gradientColor from "../../utility/gradientColor";
import Score from "./Score";

const SessionListItem = ({ session }) => {
  const formatSeconds = seconds => `${Math.floor(seconds / 60)}:${(Math.floor(seconds) % 60).toString().padStart(2, '0')}`;




  const name = session.firstName ? <span><h3>{session.firstName.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()\s?]+|\b(um|you)\b/gi, '')}</h3></span> : <span><p>Anonymous</p><h3>{session.sessionCode}</h3>`</span>;

  return (
    <div className="grid-row session-list-item">
      <div className="grid-column narrow date ">
        {moment(session.startTime).format('MM/DD/YYYY')}
      </div>
      <div className="grid-column slim name">
        {name}
      </div>
      <div className="grid-column narrow " >
        <Score score={session.meanScore}/>
      </div>
      <div className="grid-column narrow">
        <h3>{session.responseCount}</h3>
      </div>
      <div className="grid-column slim">
        <h3>{formatSeconds(session.secondsSpent)}</h3>
      </div>
      <div className="grid-column narrow">
        <Link to={`/sessions/${session.sessionCode}`}><button className="dashboard">View session</button></Link>
      </div>
     
     
    </div>
  );
}

export default SessionListItem;