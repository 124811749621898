import { useEffect, useState } from 'react';
import './Event.scss';
import './DashboardGlobal.scss';

import moment from 'moment';
import { getItem, getThumbnail } from '../../services/Content';
import ListItem from '../ListItem';
import QuestionThumbnail from '../../images/icon_question.png';
import gradientColor from '../../utility/gradientColor';
import Score from './Score';

const Event = ({ data, dashboard }) => {

  const [category, setCategory] = useState('');
  const [label, setLabel] = useState('');
  const [visible, setVisible] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);
  const [assessment, setAssessment] = useState(false);


  const formulate = () => {

    let type = data.pk.split("#")[0];


    console.log(`Type: ${type}`)

    switch (type) {
      case 'EnvironmentVisited':
        setCategory('Environment visited');
        getItem("environments", data.value, false).then(response => setLabel(response.name))

        setThumbnail(getThumbnail(data.value));
        break;

      case 'ElementExpanded':
        setCategory('Description read');
        getItem("elements", data.value, false).then(response => {
          setLabel(response.name.replace("_", " "))
        })
        setThumbnail(getThumbnail(data.value));
        break;

      case 'ConditionChanged':
        setCategory('Condition changed');
        setLabel(JSON.parse(data.value).name)
        setVisible(false)
        break;

      case 'Insight':
        setCategory('Insight')
        
        setVisible(false)

        break;

      case 'Assessment':
        setCategory('Insight')
        setThumbnail(QuestionThumbnail);
        setAssessment(true);
        break;

      // case 'SessionStarted':
      //   setCategory("Session started")
      //   break;

      
        default: 
        setVisible(false);

    }

  }


  useEffect(() => {
    formulate();
  }, [data])

  if (!visible) {
    return null; // Renders nothing
  }

  


  return (
    // <ListItem>

    <div className="event">
      <div className="first-row">
        <div className="grid-column medium left">
          {thumbnail && <img src={thumbnail} className="thumbnail" />}
          <div className="label">{label && <h2>{label}</h2>}</div>
        </div>

        <div className="grid-column wide">
          <h1>{category}</h1>
          {assessment && dashboard && <Score score={data.score}/>}
        </div>

        <div className="grid-column medium right">
          <div className="row">
            <h4>{moment(data.sk).format("MM/DD/YY")}</h4>
            <h4>{moment(data.sk).format("h:mm a")}</h4>
          </div>
          {/* <h4>View details</h4> */}
        </div>
      </div>

      {assessment && <Assessment data={data} />}
    </div>

    // </ListItem>
  );
}

export default Event;



const Assessment = ({ data }) => {
  const question = JSON.parse(data.value).text;
  const studentResponse = data.transcript;
  const botFeedback = data.student_evaluation;
  const summary = data.summary;
  const topics = data.topics;

  return (
    <div className="assessment">
      <div className="row">
        <Chat type="question">{question}</Chat>
        <Chat
          color={gradientColor(data.score) }
          type="student answer"
        >
          {studentResponse}
        </Chat>
      </div>
      <div className="row">
        <Chat type="feedback">{botFeedback}</Chat>
        <Chat type="summary">{summary}</Chat>
      </div>

      <div className="row">
        <h4>TOPICS</h4>
        <div className="row wrap">
          {topics &&
            topics.split(",").map((t, i) => (
              <div key={i} className="topic">
                {t.toLowerCase()}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

const Chat = ({children, type, color}) => {

  return (
    <div style={{background: color ? color : ""}} className={`chat ${type}`}>
      <h2>{type}</h2>
      <div>{children}</div>
      
    </div>
  )
}

