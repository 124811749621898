import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const api = axios.create({
  //staging
  // baseURL: "https://wl3b5tuvyh.execute-api.us-west-2.amazonaws.com",
  //prod
  baseURL: "https://wrt34an1h3.execute-api.us-west-2.amazonaws.com",
  responseType: "json",
});

api.interceptors.request.use(
  async (config) => {
    // Check if the custom `requiresAuth` config option is true
    // console.log(config)
    if (config.requiresAuth) {
      const session = await fetchAuthSession();
      const idToken = session.tokens.idToken;
      const authToken = session.tokens.accessToken;

      const token = authToken.toString();
      // console.log(token)
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
