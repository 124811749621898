import React, { useEffect } from 'react';
import { Authenticator, Button, Heading, Image, Text, View, useAuthenticator, useTheme } from '@aws-amplify/ui-react';

import '@aws-amplify/ui-react/styles.css'; // Import default Amplify styles which you can override
import './LoginPage.scss';
import Page from '../../components/Page';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Hub } from 'aws-amplify/utils';
import Section from '../../components/Section';
import { useAnalytics } from '../../context/AnalyticsContext';
import { usePageMetadata } from '../../context/PageMetadataContext';



const LoginPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
;
  const { setTitle, setShowTitleInHeader } = usePageMetadata();

  useEffect(() => {
    setShowTitleInHeader(true);
    setTitle("Sign In");
  }, [])

  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          {/* <Image
            alt="Amplify logo"
            src="https://docs.amplify.aws/assets/logo-dark.svg"
          /> */}
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          {/* <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text> */}
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a new account
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Enter your email',
      },
    },
    signUp: {
      password: {
        label: 'Password:',
        placeholder: 'Enter your Password:',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirm Password:',
        order: 1,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Enter your Password:',
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Enter your email:',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Enter your Confirmation Code:',
        label: 'New Label',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Enter your Password Please:',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };

  const from = location.state?.from?.pathname || '/';

  console.log(from)

  useEffect(() => {
    // Listen for authentication changes
    
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log(event)
      switch (event) {
        case 'signedIn':
          navigate(from);
          break;
        default:
          break;
      }
    });

  }, []);



  const handleAuthStateChange = (authState) => {
    console.log("handing state change", authState)
    if (authState === 'signedin') {
      navigate(from); // Redirect to the initially requested page or home
    }
  };

  return (
    <Page>
      <Authenticator components={components} loginMechanisms={['email']} hideSignUp={true} className='authenticator' onStateChange={handleAuthStateChange}>
        {/* <SignIn slot="sign-in" hideSignUp></SignIn> */}
        {({ signOut, user }) => (
         
          <Section>
            <div>
              <h1>Welcome </h1>
              <h3>{user.signInDetails && user.signInDetails.loginId}</h3>
              <button onClick={signOut}>Sign out</button>
            </div>
           
          </Section>
        )}
      </Authenticator>
    </Page>
   
  );
};

export default LoginPage;
