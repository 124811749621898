import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import API from "../services/API";


const AnalyticsContext = createContext();

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider = ({ children }) => {
  const { organizationId } = useAuth();
  const [dateRange, setDateRange] = useState({
    startDate: "2024-01-01",
    endDate: "2024-02-09",
  });
  const [insights, setInsights] = useState(null);
  const [sessions, setSessions] = useState(null);

  const [statistics, setStatistics] = useState(null);

  const [activeInsightSort, setActiveInsightSort] = useState({ attribute: "", order: true }); // order: true for ascending, false for descending

   const [activeSessionSort, setActiveSessionSort] = useState({
     attribute: "",
     order: true,
   });

  useEffect(() => {
    console.log("Org id from context", organizationId);

    organizationId && updateData();
  }, [organizationId, dateRange]);

  useEffect(() => {
    // console.log("insights updated", insights)
  }, [insights]);

  const updateData = () => {
    API.get(
      `questions?organization=${organizationId}&start=${dateRange.startDate}&end=${dateRange.endDate}`,
      {
        requiresAuth: true,
      }
    )
      .then((response) => {
        console.log("INSIGHT response", response);
        response && sortList(response.data, setInsights, "responseCount", false, setActiveInsightSort);
      })
      .catch((error) => console.log(error));

    API.get(
      `sessions?organization=${organizationId}&start=${dateRange.startDate}&end=${dateRange.endDate}`,
      {
        requiresAuth: true,
      }
    )
      .then((response) => {
        console.log("SESSION response", response);
        if (response){
          sortList(response.data, setSessions, "startTime", false, setActiveSessionSort);
          
        }
          
      })
      .catch((error) => console.log(error));

    API.get(
      `stats?organization=${organizationId}&start=${dateRange.startDate}&end=${dateRange.endDate}`,
      {
        requiresAuth: true,
      }
    )
      .then((response) => {
        console.log("STAT response", response);
        response && response.data.averageResponses != "NaN" && setStatistics(response.data);
      })
      .catch((error) => console.log(error));
  };

  const sortSessions = (attribute, ascending = true) => {
    sortList(sessions, setSessions, attribute, ascending, setActiveSessionSort);

  };

  const sortInsights = (attribute, ascending = true) => {
    sortList(insights, setInsights, attribute, ascending, setActiveInsightSort);

  };

  const sortList = (list, setList, attribute, ascending = true, setActiveSort) => {
    const sortedList = [...list].sort((a, b) => {
      if (a[attribute] < b[attribute]) {
        return ascending ? -1 : 1;
      }
      if (a[attribute] > b[attribute]) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  
    setActiveSort({ attribute, order: ascending });
    setList(sortedList);
  };

  const updateDateRange = (newDateRange) => {
    console.log("Updating date range");
    setDateRange(newDateRange);
  };

  const value = {
    insights,
    sortInsights,
    activeInsightSort,
    sessions,
    sortSessions,
    activeSessionSort,
    statistics,
    updateDateRange,
   
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
}