import { useAnalytics } from "../../context/AnalyticsContext";
import "./DashboardGlobal.scss";

const Statistics = () => {

  const { statistics } = useAnalytics();


  const formatSeconds = seconds => `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`;

  return (
    <div className="grid-row">
      <div className="grid-column medium statistic">
        <h2>Time Spent</h2>
        <h1>{formatSeconds(statistics.averageTime)}</h1>
        <h3>Average</h3>
      </div>
      <div className="grid-column medium statistic">
        <h2>sessions</h2>
        <h1>{Math.ceil(statistics.totalSessionCount)}</h1>
        <h3>Total</h3>
      </div>
      <div className="grid-column medium statistic">
        <h2>Score</h2>
        <h1>{Math.ceil(statistics.averageScore)}%</h1>
        <h3>Average</h3>
      </div>
    </div>
  );
}

export default Statistics;