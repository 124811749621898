import "./Insight.scss";
import "./DashboardGlobal.scss";
import { useEffect, useState } from "react";
import { getItem, getThumbnail } from "../../services/Content";
import Score from "./Score";

const Insight = ({ insight }) => {

    const [thumbnail, setThumbnail] = useState(null);
    const [label, setLabel] = useState(null);

    useEffect(() => {
      

      console.log(insight.environmentId)
      if(insight.environmentId != null) {

        getItem("environments", insight.environmentId, false).then((response) =>

          setLabel(response.name)
        );
        setThumbnail(getThumbnail(insight.environmentId));

      }

    }, [])
  

  return (
    <div className="grid-row insight">
      <div className="grid-column medium environment">
        {thumbnail && <img src={thumbnail} className="thumbnail" />}
        <div className="label">{label && <h2>{label}</h2>}</div>
      </div>
      <div className="grid-column wide question">{insight.textValue}</div>
      <div className="grid-column medium answers">
        <h2>{insight.responseCount}</h2>
      </div>
      <div
        className="grid-column narrow "
       
      >
       <Score score={insight.meanScore} />
      </div>
    </div>
  );
}

export default Insight;