import moment from 'moment';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./DateRangePicker.scss";



const DateRangePicker = ({ onRangeUpdated, dateRange }) => {
  const [selected, setSelected] = useState(2);

  const [endDate, setEndDate] = useState(dateRange.end);
  const [startDate, setStartDate] = useState(dateRange.start);

  const ranges = [
    {
      label: "Last day",
      value: 1,
    },
    {
      label: "Past week",
      value: 7,
    },
    {
      label: "Past month",
      value: 30,
    },
    {
      label: "Three months",
      value: 90,
    },
    // {
    //   label: "Six months",
    //   value: 182,
    // },
    // {
    //   label: "One year",
    //   value: 365,
    // },
  ];

  useEffect(() => {
    updateRange(ranges[selected].value, selected);
  }, [])

  useEffect(() => {
    onRangeUpdated && onRangeUpdated({start: startDate, end: endDate});
    console.log(`End date updated: ${endDate}`)
  }, [endDate, startDate])


  const updateRange = ( value, index ) => { 
     let formattedStart = moment(moment().subtract(value, 'days')).startOf("day").utc().format("YYYY-MM-DD");
     let formattedEnd = moment().endOf("day").utc().format("YYYY-MM-DD");
    setStartDate(formattedStart);
    setEndDate(formattedEnd);
    setSelected(index);
  }

  const renderButtons = ranges.map((range, i) => (
    <button key={i} className={`dashboard ${i == selected && 'selected'}`} onClick={() =>  updateRange(range.value, i)}>{range.label}</button>
  ))

  const setCustomStartDate = (date) => {
    // console.log("seting start", date)
    let formatted = moment(date).utc().startOf("day").format("YYYY-MM-DD");
    setStartDate(formatted)
    setSelected(-1)
  }

   const setCustomEndDate = (date) => {
    console.log(`End date: ${date}`)
    let formatted = moment(date).utc().endOf('day').format("YYYY-MM-DD");
    console.log(`Formatted: ${formatted}`)
     setEndDate(formatted);
     setSelected(-1);
   };

  return (
    <div className="date-range-picker">
      <div className="range-picker">{renderButtons}</div>

      <div className="row">
        <h3>START</h3>
        <DatePicker
          popperPlacement="left-start"
          selected={startDate}
          onChange={setCustomStartDate}
        />
      </div>

      <div className="row">
        <h3>END</h3>
        <DatePicker
          maxDate={new Date()}
          popperPlacement="left-start"
          selected={endDate}
          onChange={setCustomEndDate}
        />
      </div>
    </div>
  );

} 

export default DateRangePicker;