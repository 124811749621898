import { useAnalytics } from "../../context/AnalyticsContext";
import "./DashboardGlobal.scss";

const SessionStatistics = ({ statistics }) => {


  const formatSeconds = seconds => `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`;

  return(
    <div className='grid-row'>
      <div className='grid-column medium statistic'>
        <h2>Environments</h2>
        <h1>{statistics.environmentsVisited}</h1>
        <h3>Visited</h3>
       
      </div>
      <div className='grid-column medium statistic'>
        <h2>Descriptions</h2>
        <h1>{statistics.descriptionsRead}</h1>
        <h3>Read</h3>
      </div>
      <div className='grid-column medium statistic'>
        <h2>Insights</h2>
        <h1>{statistics.insightsCompleted}</h1>
        <h3>Completed</h3>
      </div>
      </div>
  )
}

export default SessionStatistics;