import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    
      Cognito: {
        region: 'us-west-2', // e.g. us-east-1
        userPoolId: 'us-west-2_lXa3jC19n',
        userPoolClientId: '6gdps7ruetpo87v60gko29n8tp',
        authenticationFlowType: 'USER_PASSWORD_AUTH',
      }
    }
   
  
});