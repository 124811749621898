import Page from "../components/Page";
import ContactLink from "../components/header/ContactLink";
import Header from "../components/header/Header";
import "./Demo.scss";
import { InlineWidget } from "react-calendly";

const Demo = ({}) => {
  return (
    <div>
       <Header pricing={true} code={true}>

        <ContactLink />

      </Header>
      <div className="demo">
        <div>
          <h3>Book a free 30 minute demo today</h3>
          <p>
            Meet with the team behind 10k Science and explore how we can work
            together to bring real science to learners of all ages.{" "}
          </p>
        </div>
        <div style={{ height: "100vh", width: "100%" }}>
          <InlineWidget
            url="https://calendly.com/dynamoid/10k-science-demo"
            styles={{ height: "1200px", width: "100%", top:0 }}
          />
        </div>
      </div>
    </div>
  );
}

export default Demo;