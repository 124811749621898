import { useEffect } from "react";
import "./Sessions.scss";
import "./Dashboard.scss";
import { usePageMetadata } from "../context/PageMetadataContext";
import { useAnalytics } from "../context/AnalyticsContext";
import Filters from "../components/dashboard/Filters";
import SessionListItem from "../components/dashboard/SessionListItem";
import GridHeader from "../components/dashboard/GridHeader";
import SortToggleButton from "../components/SortToggleButton";

const Sessions = () => {
  const { setTitle, setShowTitleInHeader } = usePageMetadata();

  const { sessions, sortSessions, activeSessionSort } = useAnalytics();


  
  useEffect(() => {
    setShowTitleInHeader(true);
    setTitle("Student sessions");
  }, [])

  useEffect(() => {
    console.log("SESSIONS ARE SAVED", sessions)

    // sessions.map(session => console.log(session))
  }, [sessions])



  return (
    <div className="dashboard sessions">
      <Filters />

      <div className="content">
        {sessions && (
          <GridHeader>
            <div className="grid-column narrow">
              <SortToggleButton
                attribute="startTime"
                sortFunction={sortSessions}
                activeSort={activeSessionSort}
              />
              Date
            </div>
            <div className="grid-column slim">Name</div>
            <div className="grid-column narrow">
              <SortToggleButton
                attribute="meanScore"
                sortFunction={sortSessions}
                activeSort={activeSessionSort}
              />
              Score
            </div>
            <div className="grid-column narrow">
              <SortToggleButton
                attribute="responseCount"
                sortFunction={sortSessions}
                activeSort={activeSessionSort}
              />
              Insights
            </div>
            <div className="grid-column slim">
              <SortToggleButton
                attribute="secondsSpent"
                sortFunction={sortSessions}
                activeSort={activeSessionSort}
              />
              Time spent
            </div>
            <div className="grid-column narrow"></div>
          </GridHeader>
        )}
        {sessions &&
          sessions.map((session) => (
            <SessionListItem key={session.sessionCode} session={session} />
          ))}
      </div>
    </div>
  );
}

export default Sessions;