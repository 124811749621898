import { Link } from "react-router-dom";
import "./PricingLink.scss";

const PricingLink = ({ }) => {
  return(
    <Link className="header-link" to="/pricing">
      Pricing
    </Link>
  )
}

export default PricingLink;