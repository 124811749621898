import "./DashboardGlobal.scss";

const GridHeader = ({ children }) => {
  return(
    <div className="grid-header">
      {children}
    </div>
  )
}

export default GridHeader;