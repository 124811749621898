import { Link } from "react-router-dom";
import "./PricingLink.scss";

const ContactLink = ({ }) => {
  return(
    <Link className="header-link" to="/contact">
      Contact
    </Link>
  )
}

export default ContactLink;