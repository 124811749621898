import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: "us-west-2_lXa3jC19n", // Your Cognito User Pool ID
  ClientId: "6gdps7ruetpo87v60gko29n8tp", // Your Cognito App Client ID (without client secret)
};

const UserPool = new CognitoUserPool(poolData);

export default UserPool;
