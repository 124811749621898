import gradientColor from "../../utility/gradientColor";
import "./Score.scss"

const Score = ({ score }) => {
  return (
    <div
      className="score"
      style={{ background: gradientColor(score) }}
    >
      {score}%
    </div>
  );
}

export default Score;