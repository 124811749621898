import { useEffect, useState } from "react";
import DateRangePicker from "../DateRangePicker";
import "./Filters.scss";
import { useAnalytics } from "../../context/AnalyticsContext";



const Filters = ({ onDateTimeChanged }) => {

  const { updateDateRange } = useAnalytics();

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  

  const SetDateTimes = ({start, end}) => {

    console.log("start date", start)
    if(start) setStartDate(start);
    if(end) setEndDate(end)
    
  }

  useEffect(() => {
    if(startDate && endDate) updateDateRange({ startDate, endDate });
  },[startDate, endDate])

  return(
    <div className="filters">
      <DateRangePicker dateRange={{ start: startDate, end: endDate }} onRangeUpdated={SetDateTimes} />
    </div>
  )

 
}

export default Filters;