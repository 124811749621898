import './Section.scss';

const Section = ({banner, children}) => {
  return(
    <div className={`section ${banner && 'banner'}`} >
      {children}
    </div>
  )
}

export default Section;