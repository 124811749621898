import { useRef, useState } from 'react';
import './Main.scss';
import Header from '../components/header/Header';
import splashVideo from '../videos/10k Science Explainer.mp4';
import Section from '../components/Section';
import ReactModal from 'react-modal';
import SplashImage from '../images/WebsiteBanner.png';
import NewsBar from '../components/NewsBar';
import Blurb from '../components/Blurb';
import About from '../components/About';
import SessionCodeInput from '../components/header/SessionCodeInput';
import PricingLink from '../components/header/PricingLink';
import ContactLink from '../components/header/ContactLink';
import DashboardButton from '../components/header/DashboardButton';
ReactModal.setAppElement('#root');

const Main = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalLink, setModalLink] = useState("");
  const signupRef = useRef(null);

  const openModal = (link) => {
    setModalLink(link);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const scrollToSignup = () => {
    console.log("scrolling to view")
    signupRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

 

  const PlayIcon = () => (
    <svg viewBox="0 0 24 24" fill="currentColor" className="play-icon">
      <path d="M8 5v14l11-7z"></path>
    </svg>
  );
  

  return (
    <div className="main">
      <Header pricing={true} code={true}>
        <PricingLink />
        <ContactLink />

        <SessionCodeInput />
        <DashboardButton />
      </Header>
      <NewsBar>
        <h2>
          AI instruction and assessment now available: Announcing 10k Science's
          newest feature, Guides.
        </h2>
        <button
          onClick={() =>
            openModal(
              "https://www.youtube.com/embed/mlWki9kwsdc?si=MPIz3wGLIG2nVqBg&autoplay=true"
            )
          }
        >
          Watch demo
        </button>
      </NewsBar>
      <Section banner>
        <div className="splash">
          <img src={SplashImage} width="100%" />
          <div className="tagline">
            <h1>3D immersive science exploration</h1>
            <h4>10k Science transports students into microscopic worlds, taking them inside science like never before.
            </h4>
          </div>
        </div>
        {/* <div className="splash"></div> */}
      </Section>
      <Section banner>
        <h2>Sign up for our mailing list!</h2>{" "}
        <button onClick={scrollToSignup} className="alternate">
          Sign up
        </button>
      </Section>

      <div className="video-holder">
        <video autoPlay loop muted={true} className="full-width-video">
          <source src={splashVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button
          onClick={() =>
            openModal(
              "https://player.vimeo.com/video/863364241?h=580e12f3cf&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479&autoplay=true"
            )
          }
          className="play-button"
        >
          <PlayIcon />
        </button>
      </div>

      <Section>
        <Blurb>
          <h2>Real science data</h2>
          <p>
            We work with scientists at UC Berkeley, UC Davis, and others, and
            use their real-world research as the foundation for all 10k Science
            experiences.
          </p>
        </Blurb>
        <Blurb>
          <h2>Evidence-based</h2>
          <p>
            We are committed to continuous evaluation of our products in
            authentic educational settings. Partnerships with Lawrence Hall of
            Science and similar ensures NGSS and other standards alignment.
          </p>
        </Blurb>
        <Blurb>
          <h2>Educator centered</h2>
          <p>
            We understand that most educational technology adds a huge burden:
            Our aim is to instead give educators superpowers.
          </p>
        </Blurb>
      </Section>
      <Section>
        <About />
        <iframe
          ref={signupRef}
          className="form"
          src="https://cdn.forms-content-1.sg-form.com/a244f7fa-857f-11ee-9bc8-a2f2092f9596"
          width="600px"
          height="600"
          frameborder="0"
        />
      </Section>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <iframe
          src={modalLink}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="10k Science"
          width="800"
          height="520"
        ></iframe>
      </ReactModal>
      {modalIsOpen && (
        <button onClick={closeModal} className="modal-close-button">
          &times;
        </button>
      )}
    </div>
  );
}

export default Main;
